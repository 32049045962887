import { Modal } from '@alipay/bigfish/antd';
import React, { useEffect, useState } from '@alipay/bigfish/react';
import lottie from 'lottie-web';
// import { QrCode } from '@alipay/tech-ui';
import Style from './index.less';

export default () => {
  const ref = React.useRef<any>();

  const [showAndroid, setShowAndroid] = useState(false);
  const [showIOS, setShowIOS] = useState(false);

  // 图片数据（后面可单独维护）
  // icon
  const AndroidUrl =
    'https://mdn.alipayobjects.com/huamei_dlpkmn/afts/img/A*aSPlQa1JNvMAAAAAAAAAAAAADty4AQ/original';
  const IOSUrl =
    'https://mdn.alipayobjects.com/huamei_dlpkmn/afts/img/A*rXyzRZnrFZgAAAAAAAAAAAAADty4AQ/original';
  // logo
  const logoUrl =
    'https://mdn.alipayobjects.com/huamei_bfc7ch/afts/img/A*oHvbS6VihUgAAAAAAAAAAAAADst8AQ/original';
  // landing页
  // const zhixiaobaoApp = 'https://render.zhixiaobao.com/p/s/l'
  // ios 商城链接
  // const IOSLink = 'https://apps.apple.com/cn/app/%E6%94%AF%E5%B0%8F%E5%AE%9D/id6550902974'
  // 用户协议
  const agreementUrl =
    'https://render.alipay.com/p/c/180020570000103690/index.html';
  // 隐私政策
  const privacyUrl =
    'https://render.alipay.com/p/c/180020570000103689/index.html';
  // 权限列表
  // const powerUrl = 'https://render.alipay.com/p/c/180020570000088096/index.html'

  const handleCancel = () => {
    setShowAndroid(false);
  };
  const handleIOSCancel = () => {
    setShowIOS(false);
  };
  const openAndroid = () => {
    setShowAndroid(true);
  };
  const openIOS = () => {
    setShowIOS(true);
  };
  const goToIOS = () => {
    // window.open(
    //   'https://apps.apple.com/cn/app/%E6%94%AF%E4%BB%98%E5%AE%9D-%E4%BE%BF%E6%8D%B7%E7%94%9F%E6%B4%BB-%E4%B8%80%E7%82%B9%E5%B0%B1%E5%A5%BD/id333206289',
    // );
    // do some thing
  };
  const init = () => {
    const lottiePlayer = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://mdn.alipayobjects.com/huamei_dlpkmn/afts/file/A*hXx1SpiUwP0AAAAAAAAAAAAADty4AQ',
    });
    lottiePlayer.playSegments([62, 109]);
  };

  const linkToOpen = (url: any) => {
    if (!url) return;
    window.open(url);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={Style.home}>
      <div className={Style.header}>
        <img className={Style.logo} src={logoUrl} alt="" />
      </div>
      <div className={Style.centerWrap}>
        <div className={Style.textWrap}>
          <div className={Style.contentWrap}>
            <div className={Style.contentText}>
              <span className={Style.contentAlipay}>安诊儿</span>
              能陪你看病的AI就医助理
            </div>
          </div>
          <div className={Style.download}>
            <div className={Style.downloadBtn}>
              <div className={Style.downloadItemV} onClick={openAndroid}>
                <img className={Style.imgItem} src={AndroidUrl} alt="" />
                <span className={Style.buttonText}>Android</span>
              </div>
            </div>
            <div className={Style.downloadBtn}>
              <div className={Style.downloadItem} onClick={openIOS}>
                <img className={Style.imgItem} src={IOSUrl} alt="" />
                <span className={Style.buttonText}>iOS</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.homeFooter}>
        <div className={Style.footerWrap}>
          <span
            className={Style.footerText}
            onClick={() =>
              linkToOpen(
                'https://render.alipay.com/p/c/180020570000103614/index.html',
              )
            }
          >
            关于我们
          </span>
          <span className={Style.textSplit}>|</span>
          <span
            className={Style.footerText}
            onClick={() => linkToOpen(privacyUrl)}
          >
            隐私政策
          </span>
          <span className={Style.textSplit}>|</span>
          <span
            className={Style.footerText}
            onClick={() => linkToOpen(agreementUrl)}
          >
            服务协议
          </span>
          <span className={Style.textSplit}>|</span>
          {/* <span
            className={Style.footerText}
            onClick={() => linkToOpen(powerUrl)}
          >
            权限列表
          </span>
          <span className={Style.textSplit}>|</span> */}
          <span
            className={Style.footerText}
            onClick={() =>
              linkToOpen(
                'https://render.alipay.com/p/c/180020570000103619/index.html',
              )
            }
          >
            联系方式
          </span>
        </div>
        <div className={Style.bottomWrap}>
          <img
            className={Style.bottomIcon}
            src="https://mdn.alipayobjects.com/huamei_dlpkmn/afts/img/A*wtjKQZOgv5wAAAAAAAAAAAAADty4AQ/original"
            alt=""
          />
          <a
            className={Style.bottomText}
            href="https://beian.miit.gov.cn/"
            rel="noreferrer"
            target="_blank"
          >
            浙ICP备2024132127号-1
          </a>
        </div>
      </div>
      <Modal
        // title="下载 Android 版"
        title="敬请期待"
        open={showAndroid}
        centered
        className={Style.landingModal}
        width={400}
        height={427}
        footer={null}
        onCancel={handleCancel}
      >
        <div className={Style.androidModel}>
          {/* <div className={Style.androidWrap}>
            <div className={Style.lastWrap}>
              <div className={Style.qrWrapV}>
                <QrCode
                  size={216}
                  value={zhixiaobaoApp}
                  logo="https://mdn.alipayobjects.com/huamei_dlpkmn/afts/img/A*rXehRrY93AwAAAAAAAAAAAAADty4AQ/original"
                  logoSize={48}
                />
              </div>
            </div>
          </div> */}
          <img
            className={Style.fakeQrIcon}
            src={
              'https://mdn.alipayobjects.com/huamei_bfc7ch/afts/img/A*3awmSpcbVKQAAAAAAAAAAAAADst8AQ/original'
            }
            alt=""
          />
          <div className={Style.androidNoticeV}>
            安诊儿app即将上线，敬请期待哦
          </div>
        </div>
      </Modal>
      <Modal
        // title="下载 iOS 版"
        title="敬请期待"
        open={showIOS}
        centered
        className={Style.landingModal}
        width={400}
        height={427}
        footer={null}
        onCancel={handleIOSCancel}
      >
        <div className={Style.androidModel} onClick={goToIOS}>
          {/* <div className={Style.androidWrap}>
            <div className={Style.lastWrap}>
              <div className={Style.qrWrapV}>
                <QrCode
                  size={216}
                  value={IOSLink}
                  logo="https://mdn.alipayobjects.com/huamei_dlpkmn/afts/img/A*rXehRrY93AwAAAAAAAAAAAAADty4AQ/original"
                  logoSize={48}
                />
              </div>
            </div>
          </div> */}
          <img
            className={Style.fakeQrIcon}
            src={
              'https://mdn.alipayobjects.com/huamei_bfc7ch/afts/img/A*3awmSpcbVKQAAAAAAAAAAAAADst8AQ/original'
            }
            alt=""
          />
          <div className={Style.androidNoticeV}>
            安诊儿app即将上线，敬请期待哦
          </div>
        </div>
      </Modal>
    </div>
  );
};
